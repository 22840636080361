import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PropTypes from "prop-types";
import { v4 } from "uuid";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import Content, { HTMLContent } from "../components/Content";

// services
import SmoothScrolling from "./../services/anchorLink";
import { goToCounselingForm } from "../services/counselingLinks";

// components
import CSButton from "../components/CSButton";

// share
import ShareInfomationPageTemplate from "./share-infomation-page";

// 女性料金
import femaleFacaPrice from "../data/medical_hair/prices/female/face.json"; // 顔
import femaleAdultWomenPrice from "../data/medical_hair/prices/female/adult_women.json"; // 大人女子
import femaleArmPrice from "../data/medical_hair/prices/female/arm.json"; // 腕・手
import femaleAside from "../data/medical_hair/prices/female/aside.json"; // わき
import femaleBasicWomen from "../data/medical_hair/prices/female/basic_women.json"; // 基本女子
import femaleBody from "../data/medical_hair/prices/female/body.json"; // 胴体
import femaleDelicateZone from "../data/medical_hair/prices/female/delicate_zone.json"; // デリケートゾーン
import femaleFace from "../data/medical_hair/prices/female/face.json"; // 顔
import femaleLeg from "../data/medical_hair/prices/female/leg.json"; // 足
import femaleLimbsPerfect from "../data/medical_hair/prices/female/limbs_perfect.json"; // 手足パーフェクトセット
import femaleNeck from "../data/medical_hair/prices/female/neck.json"; // 首
import femalePetitWholeBody from "../data/medical_hair/prices/female/petit_whole_body.json"; // プチ全身
import femaleWholeBody from "../data/medical_hair/prices/female/whole_body.json"; // 全身

// 男性料金
import maleFacePrice from "../data/medical_hair/prices/male/face.json"; // 顔
import maleNeckPrice from "../data/medical_hair/prices/male/neck.json"; // 首
import maleShoulderPrice from "../data/medical_hair/prices/male/shoulder.json"; // 肩
import maleAsidePrice from "../data/medical_hair/prices/male/aside.json"; // わき
import maleArmPrice from "../data/medical_hair/prices/male/arm.json"; // 腕
import maleBodyPrice from "../data/medical_hair/prices/male/body.json"; // 胴体
import maleLegPrice from "../data/medical_hair/prices/male/leg.json"; // 足
import maleWholeBodyPrice from "../data/medical_hair/prices/male/whole_body.json"; // 全身
import maleBasicMalePrice from "../data/medical_hair/prices/male/basic_male.json"; // 基本男子
import maleDelicateZone from "../data/medical_hair/prices/male/delicate_zone.json"; // デリケートゾーン

// 画像
import BodyPartsImage from "../img/medical_hair/body_parts.png";
import FemaleAdultWomenPriceImage from "../img/medical_hair/female_adult_women_price.png";
import FemaleBasicWomenPriceImage from "../img/medical_hair/female_basic_women_price.png";
import FemaleLimbsPerfectWomenPriceImage from "../img/medical_hair/female_limbs_perfect_women_price.png";
import FemalePetitWholeBodyPriceImage from "../img/medical_hair/female_petit_whole_body_price.png";
import FemalePetitWholeBodyPriceImage002 from "../img/medical_hair/female_petit_whole_body_price_003.png";
import isBlank from "../services/checkBlank";

export const MedicalhairPricePageTemplate = ({
  title,
  content,
  contentComponent,
}) => {
  const [selectedTabIndex, setTabIndex] = useState(0);
  const handleSelect = (index, last) => {
    setTabIndex(index);
  };

  return (
    <>
      <div className="c-bread">
        <div className="c-bread__inner">
          <div className="c-bread__item">
            <Link to={"/"} className="c-bread__link">
              <div className="c-bread__text">トップ</div>
            </Link>
          </div>
          <div className="c-bread__item">
            <Link to={"/medical_hair"} className="c-bread__link">
              <div className="c-bread__text">医療脱毛</div>
            </Link>
          </div>
          <div className="c-bread__item">
            <div className="c-bread__text">料金表</div>
          </div>
        </div>
      </div>

      <Tabs
        className="c-tabs"
        onSelect={handleSelect}
        selectedIndex={selectedTabIndex}
      >
        <div className="c-contents-tab__outer">
          <div className="c-contents-tab">
            <TabList className="c-contents-tab__list">
              <Tab
                className={`c-contents-tab__item ${selectedTabIndex === 0 &&
                  "is-active"}`}
              >
                <span className="c-contents-tab__text">女性料金</span>
              </Tab>
              <Tab
                className={`c-contents-tab__item ${selectedTabIndex === 1 &&
                  "is-active"}`}
              >
                <span className="c-contents-tab__text">男性料金</span>
              </Tab>
            </TabList>
          </div>
        </div>

        <TabPanel>
          <div data-tab-target="#tab1">
            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_whole_body_price"
            >
              <div className="l-main-content-hero__inner">
                <p className="c-text u-mb40">
                  価格は全て税込価格で表示しています。
                </p>

                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">全身</h2>
                </div>

                <div className="c-table">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金（税込）</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">4回セット（税込）</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">8回セット（税込）</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      {femaleWholeBody.map((item) => {
                        return (
                          <div className="c-table__body__row" key={v4()}>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                {item.parts_detail}
                              </p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">¥{item.one_time}</p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.four_times}
                              </p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.twelve_times}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <ul className="c-text-list u-pc-mt40 u-sp-mt20">
                  <li className="c-text-list__item">
                    Alex＝日本人の肌に適しているレーザー
                  </li>
                  <li className="c-text-list__item u-pc-mt10 u-sp-mt10">
                    YAG＝色黒な方や、色素沈着を起こしやすい部位に向いているレーザー
                  </li>
                </ul>
                <p className="c-text u-pc-mt30 u-sp-mt20">
                  VIO(デリケートゾーン)は色素沈着を起こしやい部位のため、YAGレーザーの照射を推奨しております。
                </p>
              </div>
            </section>

            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_petit_whole_body_price"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">プチ全身</h2>
                </div>

                <div className="c-capture -small u-pc-mb20 u-sp-mb10">
                  <img src={FemalePetitWholeBodyPriceImage002} alt="プチ全身" />
                </div>

                <div className="c-table">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金（税込）</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">4回セット（税込）</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">8回セット（税込）</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      {femalePetitWholeBody.map((item) => {
                        return (
                          <div className="c-table__body__row" key={v4()}>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                {item.parts_detail}
                              </p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">¥{item.one_time}</p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.four_times}
                              </p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.twelve_times}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_adult_women_price"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">大人女子</h2>
                </div>

                <div className="c-capture -small u-pc-mb20 u-sp-mb10">
                  <img src={FemaleAdultWomenPriceImage} alt="大人女子" />
                </div>

                <div className="c-table">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金（税込）</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">4回セット（税込）</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">8回セット（税込）</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      {femaleAdultWomenPrice.map((item) => {
                        return (
                          <div className="c-table__body__row" key={v4()}>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                {item.parts_detail}
                              </p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">¥{item.one_time}</p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.four_times}
                              </p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.twelve_times}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <ul className="c-text-list u-pc-mt40 u-sp-mt20">
                  <li className="c-text-list__item">
                    Alex＝日本人の肌に適しているレーザー
                  </li>
                </ul>
              </div>
            </section>

            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_basic_women_price"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">基本女子</h2>
                </div>

                <div className="c-capture -small u-pc-mb20 u-sp-mb10">
                  <img src={FemaleBasicWomenPriceImage} alt="基本女子" />
                </div>

                <div className="c-table">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金（税込）</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">4回セット（税込）</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">8回セット（税込）</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      {femaleBasicWomen.map((item) => {
                        return (
                          <div className="c-table__body__row" key={v4()}>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                {item.parts_detail}
                              </p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">¥{item.one_time}</p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.four_times}
                              </p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.twelve_times}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_limbs_perfect_women_price"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    手足パーフェクトセット＋わき
                  </h2>
                </div>

                <div className="c-capture -small u-pc-mb20 u-sp-mb10">
                  <img
                    src={FemaleLimbsPerfectWomenPriceImage}
                    alt="手足パーフェクトセット＋わき"
                  />
                </div>

                <div className="c-table">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金（税込）</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">4回セット（税込）</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">8回セット（税込）</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      {femaleLimbsPerfect.map((item) => {
                        return (
                          <div className="c-table__body__row" key={v4()}>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                {item.parts_detail}
                              </p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">¥{item.one_time}</p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.four_times}
                              </p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.twelve_times}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_face_price"
            >
              <div className="l-main-content-hero__inner">
                {/* <p className="c-text u-mb20">
                  価格は全て税込価格で表示しています
                </p> */}
                <div className="c-capture u-pc-mb20 u-sp-mb10">
                  <img src={BodyPartsImage} alt="部位" />
                </div>
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">顔</h2>
                </div>

                <div className="c-table">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金（税込）</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">4回セット（税込）</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">12回セット（税込）</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      {femaleFacaPrice.map((item) => {
                        return (
                          <div className="c-table__body__row" key={v4()}>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                {item.parts_detail}
                              </p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">¥{item.one_time}</p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.four_times}
                              </p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.twelve_times}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_neck_price"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">首</h2>
                </div>

                <div className="c-table">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金（税込）</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">4回セット（税込）</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">8回セット（税込）</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      {femaleNeck.map((item) => {
                        return (
                          <div className="c-table__body__row" key={v4()}>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                {item.parts_detail}
                              </p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">¥{item.one_time}</p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.four_times}
                              </p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.twelve_times}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_arm_price"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">腕、手</h2>
                </div>

                <div className="c-table">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金（税込）</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">4回セット（税込）</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">8回セット（税込）</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      {femaleArmPrice.map((item) => {
                        return (
                          <div className="c-table__body__row" key={v4()}>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                {item.parts_detail}
                              </p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">¥{item.one_time}</p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.four_times}
                              </p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.twelve_times}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_body_price"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">胴体</h2>
                </div>

                <div className="c-table">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金（税込）</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">4回セット（税込）</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">8回セット（税込）</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      {femaleBody.map((item) => {
                        return (
                          <div className="c-table__body__row" key={v4()}>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                {item.parts_detail}
                              </p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">¥{item.one_time}</p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.four_times}
                              </p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.twelve_times}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_leg_price"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">足</h2>
                </div>

                <div className="c-table">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金（税込）</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">4回セット（税込）</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">8回セット（税込）</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      {femaleLeg.map((item) => {
                        return (
                          <div className="c-table__body__row" key={v4()}>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                {item.parts_detail}
                              </p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">¥{item.one_time}</p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.four_times}
                              </p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.twelve_times}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              className="l-section-lv2 l-main-content-hero"
              id="female_delicate_zoon_price"
            >
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    デリケートゾーン
                  </h2>
                </div>

                <div className="c-table">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金（税込）</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">4回セット（税込）</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">8回セット（税込）</p>
                      </div>
                    </div>
                    <div className="c-table__body">
                      {femaleDelicateZone.map((item) => {
                        return (
                          <div className="c-table__body__row" key={v4()}>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                {item.parts_detail}
                              </p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">¥{item.one_time}</p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.four_times}
                              </p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.twelve_times}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <ul className="c-text-list u-pc-mt40 u-sp-mt20">
                  <li className="c-text-list__item">
                    Alex＝日本人の肌に適しているレーザー
                  </li>
                </ul>
              </div>
            </section>
          </div>
        </TabPanel>

        <TabPanel>
          <div data-tab-target="#tab2">
            <section className="l-section-lv2 l-main-content-hero">
              <div className="l-main-content-hero__inner">
                <p className="c-text u-mb40">
                  価格は全て税込価格で表示しています。
                </p>

                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">全身</h2>
                </div>

                <div className="c-table">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金（税込）</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">5回セット（税込）</p>
                      </div>
                      {/* <div className="c-table__head__item">
                        <p className="c-table__text">4回セット（税込）</p>
                      </div> */}
                      {/* <div className="c-table__head__item">
                        <p className="c-table__text">8回セット（税込）</p>
                      </div> */}
                    </div>
                    <div className="c-table__body">
                      {maleWholeBodyPrice.map((item) => {
                        return (
                          <div className="c-table__body__row" key={v4()}>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                {item.parts_detail}
                              </p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">¥{item.one_time}</p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.five_times}
                              </p>
                            </div>
                            {/* <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.four_times}
                              </p>
                            </div> */}
                            {/* <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.twelve_times}
                              </p>
                            </div> */}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="l-section-lv2 l-main-content-hero">
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">基本男子</h2>
                </div>

                <div className="c-table">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金（税込）</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">5回セット（税込）</p>
                      </div>
                      {/* <div className="c-table__head__item">
                        <p className="c-table__text">8回セット（税込）</p>
                      </div> */}
                    </div>
                    <div className="c-table__body">
                      {maleBasicMalePrice.map((item) => {
                        return (
                          <div className="c-table__body__row" key={v4()}>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                {item.parts_detail}
                              </p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">¥{item.one_time}</p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.five_times}
                              </p>
                            </div>
                            {/* <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.twelve_times}
                              </p>
                            </div> */}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="l-section-lv2 l-main-content-hero">
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">顔</h2>
                </div>

                <div className="c-table">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金（税込）</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">5回セット（税込）</p>
                      </div>
                      {/* <div className="c-table__head__item">
                        <p className="c-table__text">12回セット（税込）</p>
                      </div> */}
                    </div>
                    <div className="c-table__body">
                      {maleFacePrice.map((item) => {
                        return (
                          <div className="c-table__body__row" key={v4()}>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                {item.parts_detail}
                              </p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">¥{item.one_time}</p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.five_times}
                              </p>
                            </div>
                            {/* <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.twelve_times}
                              </p>
                            </div> */}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="l-section-lv2 l-main-content-hero">
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">首</h2>
                </div>

                <div className="c-table">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金（税込）</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">5回セット（税込）</p>
                      </div>
                      {/* <div className="c-table__head__item">
                        <p className="c-table__text">8回セット（税込）</p>
                      </div> */}
                    </div>
                    <div className="c-table__body">
                      {maleNeckPrice.map((item) => {
                        return (
                          <div className="c-table__body__row" key={v4()}>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                {item.parts_detail}
                              </p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">¥{item.one_time}</p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.five_times}
                              </p>
                            </div>
                            {/* <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.twelve_times}
                              </p>
                            </div> */}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="l-section-lv2 l-main-content-hero">
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">腕・手</h2>
                </div>

                <div className="c-table">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金（税込）</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">5回セット（税込）</p>
                      </div>
                      {/* <div className="c-table__head__item">
                        <p className="c-table__text">8回セット（税込）</p>
                      </div> */}
                    </div>
                    <div className="c-table__body">
                      {maleArmPrice.map((item) => {
                        return (
                          <div className="c-table__body__row" key={v4()}>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                {item.parts_detail}
                              </p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">¥{item.one_time}</p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.five_times}
                              </p>
                            </div>
                            {/* <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.twelve_times}
                              </p>
                            </div> */}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="l-section-lv2 l-main-content-hero">
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">胴体</h2>
                </div>

                <div className="c-table">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金（税込）</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">5回セット（税込）</p>
                      </div>
                      {/* <div className="c-table__head__item">
                        <p className="c-table__text">8回セット（税込）</p>
                      </div> */}
                    </div>
                    <div className="c-table__body">
                      {maleBodyPrice.map((item) => {
                        return (
                          <div className="c-table__body__row" key={v4()}>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                {item.parts_detail}
                              </p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">¥{item.one_time}</p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.five_times}
                              </p>
                            </div>
                            {/* <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.twelve_times}
                              </p>
                            </div> */}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="l-section-lv2 l-main-content-hero">
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">足・脚</h2>
                </div>

                <div className="c-table">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金（税込）</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">5回セット（税込）</p>
                      </div>
                      {/* <div className="c-table__head__item">
                        <p className="c-table__text">8回セット（税込）</p>
                      </div> */}
                    </div>
                    <div className="c-table__body">
                      {maleLegPrice.map((item) => {
                        return (
                          <div className="c-table__body__row" key={v4()}>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                {item.parts_detail}
                              </p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">¥{item.one_time}</p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.five_times}
                              </p>
                            </div>
                            {/* <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.twelve_times}
                              </p>
                            </div> */}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="l-section-lv2 l-main-content-hero">
              <div className="l-main-content-hero__inner">
                <div className="c-h2-left-border-title">
                  <h2 className="c-h2-left-border-title__text">
                    デリケートゾーン
                  </h2>
                </div>

                <div className="c-table">
                  <div className="c-table__inner">
                    <div className="c-table__head">
                      <div className="c-table__head__item">
                        <p className="c-table__text">部位</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">1回料金（税込）</p>
                      </div>
                      <div className="c-table__head__item">
                        <p className="c-table__text">5回セット（税込）</p>
                      </div>
                      {/* <div className="c-table__head__item">
                        <p className="c-table__text">8回セット（税込）</p>
                      </div> */}
                    </div>
                    <div className="c-table__body">
                      {maleDelicateZone.map((item) => {
                        return (
                          <div className="c-table__body__row" key={v4()}>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                {item.parts_detail}
                              </p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">¥{item.one_time}</p>
                            </div>
                            <div className="c-table__body__item">
                              <p className="c-table__text">
                                {isBlank(item.five_times)
                                  ? ""
                                  : `¥${item.five_times}`}
                              </p>
                            </div>
                            {/* <div className="c-table__body__item">
                              <p className="c-table__text">
                                ¥{item.twelve_times}
                              </p>
                            </div> */}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </TabPanel>
      </Tabs>
    </>
  );
};

MedicalhairPricePageTemplate.propTypes = {
  title: PropTypes.string,
};

const checkHash = () => {
  let hash = window.location.hash;
  if (hash === "#female-face-price") {
    SmoothScrolling.scrollTo("female_face_price", null);
  }

  if (hash === "#female-neck-price") {
    SmoothScrolling.scrollTo("female_neck_price", null);
  }

  if (hash === "#female-side-price") {
    SmoothScrolling.scrollTo("female_side_price", null);
  }

  if (hash === "#female-arm-price") {
    SmoothScrolling.scrollTo("female_arm_price", null);
  }

  if (hash === "#female-leg-price") {
    SmoothScrolling.scrollTo("female_leg_price", null);
  }

  if (hash === "#female-body-price") {
    SmoothScrolling.scrollTo("female_body_price", null);
  }

  if (hash === "#female-delicate-zoon-price") {
    SmoothScrolling.scrollTo("female_delicate_zoon_price", null);
  }

  if (hash === "#female-basic-women-price") {
    SmoothScrolling.scrollTo("female_basic_women_price", null);
  }

  if (hash === "#female-adult-women-price") {
    SmoothScrolling.scrollTo("female_adult_women_price", null);
  }

  if (hash === "#female-limbs-perfect-women-price") {
    SmoothScrolling.scrollTo("female_limbs_perfect_women_price", null);
  }

  if (hash === "#female-whole-body-price") {
    SmoothScrolling.scrollTo("female_whole_body_price", null);
  }
};

const MedicalhairPricePage = ({ data }) => {
  const { markdownRemark: post } = data;

  useEffect(() => {
    checkHash();
  });

  return (
    <Layout>
      <SEO
        title="【医療脱毛・全身脱毛】福岡天神で医療脱毛するなら天神皮ふ科"
        description="福岡の天神皮ふ科では、患者様の肌質に合わせた全身脱毛・医療脱毛の施術を行っております。問診～施術まで皮膚科専門医が担当し、質の高い脱毛をご提供するため厚生労働省認可の医療脱毛機を使用していますので、安心して施術を受けていただけます。"
        keywords="福岡市,天神,皮膚科,天神皮ふ科,脱毛,美容診療"
      />
      <MedicalhairPricePageTemplate title={post.frontmatter.title} />

      <CSButton
        text="無料カウンセリングを申し込む"
        onClick={() =>
          goToCounselingForm({ urlParams: "?tenjin-hifuka_hp_price" })
        }
      />

      <ShareInfomationPageTemplate />
    </Layout>
  );
};

MedicalhairPricePage.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.string,
};

export default MedicalhairPricePage;

export const medicalhairPricePageQuery = graphql`
  query MedicalhairPricePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
